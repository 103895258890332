//import RecorderScript from '@/classes/RecorderScript';

export default class MatiResponse {
  constructor(){

  }

  

  getStreetAddress(arrayAddress){
    let street = "";

    for (let i = 0; i < arrayAddress.length; i++) {
      var index = parseInt(arrayAddress[i]);

      if(isNaN(index)){
        street += arrayAddress[i] != "" ? arrayAddress[i].replace("/", "") + " " : arrayAddress[i] + " ";
      }
      else
      {
        break;
      }
      
    }
    return street.trimEnd();
  }

  getNumberAddress(arrayAddress){
    let numbers = [];

    for (let i = 0; i < arrayAddress.length; i++) {
      var index = parseInt(arrayAddress[i]);

      if(!isNaN(index)){
        numbers.push(arrayAddress[i]);
      }
      
    }
    return numbers.length >= 2 ? numbers[0] : "";
  }

getPostalCodeAddress(arrayAddress){
    let postalCode = "";

    for (let i = 0; i < arrayAddress.length; i++) {
      var index = parseInt(arrayAddress[i]);

      if (index.toString().length==5) {
        if(!isNaN(index)){
          postalCode = arrayAddress[i];
        break;
        }
      }
    }
    return postalCode;
  }

}